import { message } from "antd";
export const getDeviceWidth = () => {
  const width = window.innerWidth;

  if (width >= 1600) {
    return "xxl";
  }
  if (width >= 1200) {
    return "xl";
  }
  if (width >= 992) {
    return "lg";
  }
  if (width >= 768) {
    return "md";
  }
  if (width >= 567) {
    return "sm";
  }
  return "xs";
};

export const isMobile = () => {
  return getDeviceWidth() === "xs";
};
/**
 * Date 객체에 특정 일수를 더한다.
 * @param date
 * @param days
 */
export function addDay(date: Date, days: number): Date {
  return new Date(date.getTime() + 60 * 60 * 24 * 1000 * days);
}
export function getCookie(name: string) {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
}
export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export function showMessage(msg: string) {
  return message.error(msg);
}
export function showSuccessMessage(msg: string) {
  return message.success(msg);
}

// 우클릭 시 이동할 URL
export function redirectToUrl(url: string, event?: any) {
  if(event) {
    event.preventDefault(); // 기본 컨텍스트 메뉴 방지
  }

  window.location.href = url; // 이동할 URL
};

export function cleanWhitespace (text: string): string {
  // 1. 공백 1칸을 모두 제거
  let cleanedText = text.replace(/(?<!\s)\s(?!\s)/g, '');

  // 2. 2칸 이상의 공백을 1칸으로 변환
  cleanedText = cleanedText.replace(/\s{2,}/g, ' ');

  // 3. null 문자 제거
  cleanedText = cleanedText.replace(/\u0000/g, '');

  return cleanedText.trim(); // 앞뒤 공백 제거
}
